/**
 * @Description: 公共函数
 * @Author:zhaoyun
 */

/**
 * 判断一个对象是否存在key，如果传入第二个参数key，则是判断这个obj对象是否存在key这个属性
 * 如果没有传入key这个参数，则判断obj对象是否有键值对
 */
export const hasKey = (obj, key) => {
  if (key) {
    return key in obj;
  } else {
    const keysArr = Object.keys(obj);
    return keysArr.length;
  }
};

/**
 * @param {String} url
 * @description 从URL中解析参数
 */
export const getParams = url => {
  const ulrArr = url.split('?')
  if (ulrArr.length <=1) {
    return {}
  }
  const keyValueArr = ulrArr[1].split('&')
  let paramObj = {};
  keyValueArr.forEach(item => {
    const keyValue = item.split("=");
    paramObj[keyValue[0]] = keyValue[1];
  });
  return paramObj;
};

/**
 * @msg: 获取系统当前时间
 * @param {string} fmt 时间格式 具体看代码
 * @return: string
 */
export const getDate = fmt => {
  let time = "";
  const date = new Date();
  const o = {
    "M+": date.getMonth() + 1, // 月份
    "d+": date.getDate(), // 日
    "H+": date.getHours(), // 小时
    "m+": date.getMinutes(), // 分
    "s+": date.getSeconds(), // 秒
    "q+": Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds() // 毫秒
  };
  if (/(y+)/.test(fmt)) {
    time = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  for (const k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      time = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
    }
  }
  return time;
};
/**
 * @msg: 获取系统当前时间
 * @param {string} date 时间
 * @param {string} fmt 时间格式
 * @return: string
 */
export const formatDate = (date, fmt) => {
  if (!date) {
    return "";
  }
  let o = {
    "M+": date.getMonth() + 1, // 月份
    "d+": date.getDate(), // 日
    "H+": date.getHours(), // 小时
    "m+": date.getMinutes(), // 分
    "s+": date.getSeconds(), // 秒
    "q+": Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds() // 毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
    }
  }
  return fmt;
};

/**
 * 校验手机号是否正确
 * @param phone 手机号
 */

export const verifyPhone = phone => {
  const reg = /^1[0-9]{10}$/;
  const _phone = phone.toString().trim();
  let toastStr =
    _phone === ""
      ? "手机号不能为空~"
      : !reg.test(_phone) && "请输入正确手机号~";
  return {
    errMsg: toastStr,
    done: !toastStr,
    value: _phone
  };
};

export const verifyStr = (str, text) => {
  const _str = str.toString().trim();
  const toastStr = _str.length ? false : `请输入${text}～`;
  return {
    errMsg: toastStr,
    done: !toastStr,
    value: _str
  };
};
// 截取字符串
export const sliceStr = (str, sliceLen) => {
  if (!str) {
    return "";
  }
  let realLength = 0;
  const len = str.length;
  let charCode = -1;
  for (let i = 0; i < len; i++) {
    charCode = str.charCodeAt(i);
    if (charCode >= 0 && charCode <= 128) {
      realLength += 1;
    } else {
      realLength += 2;
    }
    if (realLength > sliceLen) {
      return `${str.slice(0, i)}...`;
    }
  }

  return str;
};

/**
 * JSON 克隆
 * @param {Object | Json} jsonObj json对象
 * @return {Object | Json} 新的json对象
 */
export const objClone = jsonObj => {
  let buf;
  if (jsonObj instanceof Array) {
    buf = [];
    let i = jsonObj.length;
    while (i--) {
      buf[i] = objClone(jsonObj[i]);
    }
    return buf;
  } else if (jsonObj instanceof Object) {
    buf = {};
    for (let k in jsonObj) {
      buf[k] = objClone(jsonObj[k]);
    }
    return buf;
  } else {
    return jsonObj;
  }
};

/**
 * 节流函数
 * @param {Function} fn 节流函数
 * @param {Number} interval 时间ms
 */
export function throttle(fn, interval) {
  let last = 0;
  return function() {
    let context = this;
    let args = arguments;
    let now = +new Date();
    if (now - last >= interval) {
      last = now;
      fn.apply(context, args);
    }
  };
}

export function getInBuState(stateStr) {
  let state = 0;
  switch (stateStr) {
    case "填写基本信息":
      state = 0;
      break;
    case "信息填写":
      state = 0;
      break;
    case "人脸识别":
      state = 1;
      break;
    case "用户确认":
      state = 10;
      break;
    case "合同签署":
      state = 12;
      break;
    case "工商登记":
      state = 20;
      break;
    case "开办登记":
      state = 20;
      break;
    case "确认工商登记":
      state = 21;
      break;
    case "工商初审":
      state = 21;
      break;
    case "工商核名":
      state = 21;
      break;
    case "电子签章":
      state = 21;
      break;
    case "工商终审":
      state = 21;
      break;
    case "签章":
      state = 21;
      break;
    case "税务登记":
      state = 30;
      break;
    case "税务采集":
      state = 31;
      break;
    case "确认税务登记":
      state = 31;
      break;
    case "资料反馈":
      state = 32;
      break;
    case "办税添加":
      state = 32;
      break;
    case "电子税务局登记": // 弹窗, 是否
      state = 32;
      break;
    case "确认电子税务局登记":
      state = 33;
      break;
    case "税务认证": // 全亮, 可以开票
      state = 34;
      break;
    case "税种核定": // 全亮, 可以开票
      state = 34;
      break;
    case "票种核定": // 全亮, 可以开票
      state = 34;
      break;
    case "UsbKey领取": // 全亮, 可以开票
      state = 34;
      break;
    case "UK领取": // 全亮, 可以开票
      state = 34;
      break;
    case "终审":
      state = 39;
      break;
    case "办理完结":
      state = 39;
      break;
    case "Finish":
      state = 40;
      break;
    default:
      state = 0;
      break;
  }
  return state;
}

// 得到staus Laebl
export const handleGetType = (text, type) => {
  let label = "";
  type.forEach(item => {
    if (item.value === Number(text)) {
      label = item.label;
    }
  });
  return label;
};

// 用户申请地区
export const ApplyUserArea = [
  {
    label: "中国香港",
    value: 1
  },
  {
    label: "中国澳门",
    value: 2
  },
  {
    label: "中国台湾",
    value: 3
  }
];

// 用户证件类型
export const ApplyUserIdCardType = [
  {
    label: "居民身份证",
    value: 1
  },
  {
    label: "香港居民来往内地通行证",
    value: 2
  },
  {
    label: "香港居民居住证",
    value: 3
  },
  {
    label: "台湾居民往来大陆通行证",
    value: 4
  },
  {
    label: "台湾居民居住证",
    value: 5
  },
  {
    label: "澳门居民来往内地通行证",
    value: 6
  },
  {
    label: "澳门居民居住证",
    value: 7
  }
];

// 用户性别
export const UserSexType = [
  {
    label: "男",
    value: 1
  },
  {
    label: "女",
    value: 2
  }
];
