<!--
 * @Author: SongYijie
 * @Date: 2020-03-11 09:32:19
 * @LastEditors: SongYijie
-->
<template>
  <div class='tax-prove'>
    <div class="process">
      <ImagePreview :imgUrl="require('@assets/images/individualBusiness/txp1.jpg')" alt="税务登记流程" />
      <ImagePreview :imgUrl="require('@assets/images/individualBusiness/txp2.jpg')" alt="税务登记流程" />
      <ImagePreview :imgUrl="require('@assets/images/individualBusiness/txp3.jpg')" alt="税务登记流程" />
      <ImagePreview :imgUrl="require('@assets/images/individualBusiness/txp4.jpg')" alt="税务登记流程" />
      <ImagePreview :imgUrl="require('@assets/images/individualBusiness/txp5.jpg')" alt="税务登记流程" />
    </div>
    
    <div class='footer-bt'>
      <div class="oper">
        <p>您是否已经完成电子税务局注册?</p>
        <mt-radio
          v-model="isCheck"
          :options="['是', '否']"
        >
        </mt-radio>
        <mt-field v-if="isCheck==='否'" label="原因:" placeholder="请输入您未完成电子税务局注册的原因" type="textarea" rows="4" v-model="remarks"></mt-field>
      </div>
      <mt-button type='danger' size='large' @click.native='throttleHandleNext'>确定</mt-button>
    </div>
  </div>
</template>

<script>
  import ImagePreview from '@components/image-preview';
  import { throttle } from "@utils/common";
  import { Toast } from 'mint-ui';
  import { operTaxProve } from '@api/individualBusiness';
  export default {
    name: 'taxProve',
    components: {
      ImagePreview
    },
    data() {
      return {
        isCheck: '',
        remarks: ''
      }
    },
    computed: {},
    watch: {},
    mounted() {},
    methods: {
      throttleHandleNext: throttle(
        function() {
          this.handleNext();
        }, 2000
      ),
      handleNext() {
        if (this.isCheck === '否') {
          const str = this.remarks.replace(/^\s*|\s*$/g,"");
          if (str.length <= 0) {
            Toast('原因不能为空');
            return;
          }
        }
        const data = {
          remarks: this.isCheck === '否' ? 0 : 1,
          approval: this.remarks
        }
        operTaxProve(data).then(res => {
          if (res.code === 200) {
            this.$router.back(-1)
          } else {
            Toast(res.message);
          }
        })
      }
    }
  };
</script>

<style lang='less' scoped>
.tax-prove {
  padding: 0 9vw 26.6667vw;
}
.oper > p {
  font-size: 4.8vw;
  color: #666666;
  margin-bottom: 3.2vw;
}
.mint-radiolist {
  display: flex;
  text-align: left;
}
.process {
  width: 100%;
  img {
    width: 100%;
  }
}
</style>